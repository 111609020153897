/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ModalPage.css';

const ModalPage = ({ showModal, setShowModal, message }) => {
  return (
    <>
      <Modal
        className='successfullyorderModal'
        show={showModal}
        centered
        onHide={() => setShowModal(false)}>
        <Modal.Body>
          {message?.message ? (
            <>
              <p>{message.message.message}</p>
              <p>
                <b>Order Id: </b> {message.order_id}
              </p>
              <p className='totalPera'>
                <b>Total: </b> ₹ {message.order_total}
              </p>
            </>
          ) : (
            <p>{message}</p>
          )}
          <div className='backBtn'>
            <Button variant='secondary' onClick={() => setShowModal(false)}>
              Back
            </Button>
          </div>
          {/* <p className="retryPera">Extremely sorry, the order could not be placed. Would you like to retry order.</p>
               <div className="backBtn">
                  <Button
                     variant="secondary"
                  >
                     No thanks!
                  </Button>
                  <Button
                     variant="secondary"
                     className="btnRetry"
                  >
                     Retry
                  </Button>
               </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPage;
