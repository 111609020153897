/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryContext } from '../../contextAPIs/category.context';
import RightArrow from '../../Assets/Images/Right-Arrow-Download-Transparent-PNG-Image.png';
import { getCartSummary } from '../../Helper/getCartSummary';
import { currencySymbol } from '../../Helper/currencyHelper';

import { useHistory } from 'react-router';
import './ProcessToPay.css';
const ProcessToPay = ({ deliveryMode, ...props }) => {
  const { orderNote } = props?.location?.state;
  const history = useHistory();
  const { store_details, store_menu } = useContext(CategoryContext);
  const [openAddress, setOpenAddress] = useState(false);
  const [inputs, setInputs] = useState({});

  const [note, setNote] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null); // TODO: No need for this. Use selected flag in savedAddresses

  const [cart, setCart] = useState({});
  useEffect(() => {
    const cartSummary = getCartSummary({
      store_details,
      store_menu,
      deliveryMode,
    });

    // let formattedItems = cartSummary.items?.map((item, index) => {
    //   if (
    //     item.modifiersWithNote[0].modifier &&
    //     Object.keys(item.modifiersWithNote[0].modifier).length === 0 &&
    //     Object.getPrototypeOf(item.modifiersWithNote[0].modifier) ===
    //       Object.prototype
    //   ) {
    //     return {
    //       id: item.id,
    //       name: item.name,
    //       quantity: item.modifiersWithNote.length,
    //       // note: item.modifiersWithNote.map((e) => e.note).join('|'),
    //       note: item.modifiersWithNote.map((e) => e.note).join(''),
    //       price: item.price.amount,
    //     };
    //   } else {
    //     let singleItem = [];
    //     item.modifiersWithNote.forEach((element) => {
    //       let modifier = [];
    //       let modifierDetails;
    //       for (const [modifierKey, modifierValue] of Object.entries(
    //         element?.modifier
    //       )) {
    //         if (Array.isArray(modifierValue)) {
    //           let checkboxModifier = modifierValue.map((md) => {
    //             return {
    //               // ...store_menu?.items?.[md],
    //               groupId: store_menu?.modifierGroups?.[modifierKey].id,
    //               groupName: store_menu?.modifierGroups?.[modifierKey].name,
    //               id: store_menu?.items?.[md].id,
    //               name: store_menu?.items?.[md].name,
    //               price: store_menu?.items?.[md].price.amount,
    //               quantity: 1,
    //               modifiers: [],
    //             };
    //           });
    //           modifier.push(...checkboxModifier);
    //         } else {
    //           modifierDetails = {
    //             // ...store_menu?.modifierGroups?.[modifierKey],
    //             groupId: store_menu?.modifierGroups?.[modifierKey].id,
    //             groupName: store_menu?.modifierGroups?.[modifierKey].name,
    //             id: store_menu?.items?.[modifierValue].id,
    //             name: store_menu?.items?.[modifierValue].name,
    //             price: store_menu?.items?.[modifierValue].price.amount,
    //             quantity: 1,
    //             modifiers: [],
    //           };
    //           modifier.push(modifierDetails);
    //         }
    //       }
    //       singleItem.push({
    //         id: item.id,
    //         name: item.name,
    //         quantity: 1,
    //         // note: item.modifiersWithNote.map((e) => e.note).join('|'),
    //         note: item.modifiersWithNote.map((e) => e.note).join(''),
    //         price: item.price.amount,
    //         modifiers: [...modifier],
    //       });
    //     });
    //     return singleItem;
    //     // return {
    //     //   id: item.id,
    //     //   name: item.name,
    //     //   quantity: 1,
    //     //   // note: item.modifiersWithNote.map((e) => e.note).join('|'),
    //     //   note: item.modifiersWithNote.map((e) => e.note).join(''),
    //     //   price: item.price.amount,
    //     //   // modifiers: [...modifier],
    //     //   modifiers: [],
    //     // };
    //   }
    // });

    // let newCartItemArray = [];
    // formattedItems.forEach((SI, index) => {
    //   if (Array.isArray(SI)) {
    //     SI.forEach((si) => newCartItemArray.push(si));
    //   } else {
    //     newCartItemArray.push(SI);
    //   }
    // });
    setCart(cartSummary);
  }, [deliveryMode, store_details, store_menu]);

  const [savedUserInfo, setSavedUserInfo] = useState();
  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    setInputs((state) => ({ ...state, [name]: value }), []);
  }, []);

  const handleClick = () => {
    const { tag, ...rest } = inputs;

    if (savedUserInfo)
      Object.keys(savedUserInfo)?.map((tagKey, index) => {
        savedUserInfo[tagKey].selected = false;
      });

    const userInfo = {
      ...savedUserInfo,
      [tag ?? 'other']: {
        ...rest,
        country: store_details.store_country,
        selected: true,
      },
    };
    localStorage.setItem('@userInfo', JSON.stringify(userInfo));

    setSavedUserInfo(userInfo);
    setSelectedAddress({ ...rest, key: tag, selected: true });
    setOpenAddress(false);
    setInputs({});
  };

  const handleSelectedAddress = (data) => {
    const { key, ...rest } = data;
    const userInfo = JSON.parse(localStorage.getItem('@userInfo'));
    Object.keys(userInfo).map((tagKey, index) => {
      userInfo[tagKey].selected = false;
    });
    userInfo[key].selected = true;
    localStorage.setItem('@userInfo', JSON.stringify(userInfo));
    setSavedUserInfo(userInfo);
    setSelectedAddress(data);
  };

  const handlePayment = async () => {
    const { items, ...orderTotal } = cart;
    const receipt = String(Math.floor(1000 + Math.random() * 9000));
    const totalAmount = orderTotal.total * 100;
    const data = JSON.stringify({
      call_type: 'NEW_ORDER_ID',
      amount: totalAmount,
      currency: store_details.store_currency,
      receipt: receipt,
      account_id: store_details.store_rp_merchant_id,
    });
    try {
      let response = await fetch(process.env.REACT_APP_ORDER_URL, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'test-key',
        },
      });
      let result = await response.json();
      if (result?.message?.id) {
        const razorpayOptions = {
          key: process.env.REACT_APP_PARTNER_KEY,
          amount: totalAmount,
          currency: store_details.store_currency,
          //name: 'Toque.Life',
          name: store_details.store_name,
          description: '',
          image: 'https://www.publicdomainpictures.net/pictures/30000/nahled/plain-white-background.jpg',
          order_id: result?.message?.id,
          account_id: store_details.store_rp_merchant_id,
          handler: function (response) {
            const {
              razorpay_payment_id,
              razorpay_order_id,
              razorpay_signature,
            } = response;

            let formattedItems = items?.map((item, index) => {
              if (
                item.modifiersWithNote[0].modifier &&
                Object.keys(item.modifiersWithNote[0].modifier).length === 0 &&
                Object.getPrototypeOf(item.modifiersWithNote[0].modifier) ===
                  Object.prototype
              ) {
                return {
                  id: item.id,
                  name: item.name,
                  quantity: item.modifiersWithNote.length,
                  // note: item.modifiersWithNote.map((e) => e.note).join('|'),
                  note: item.modifiersWithNote.map((e) => e.note).join(''),
                  price: item.price.amount,
                };
              } else {
                let singleItem = [];
                item.modifiersWithNote.forEach((element) => {
                  let modifier = [];
                  let modifierDetails;
                  for (const [modifierKey, modifierValue] of Object.entries(
                    element?.modifier
                  )) {
                    if (Array.isArray(modifierValue)) {
                      let checkboxModifier = modifierValue.map((md) => {
                        return {
                          // ...store_menu?.items?.[md],
                          groupId: store_menu?.modifierGroups?.[modifierKey].id,
                          groupName:
                            store_menu?.modifierGroups?.[modifierKey].name,
                          id: store_menu?.items?.[md].id,
                          name: store_menu?.items?.[md].name,
                          price: store_menu?.items?.[md].price.amount,
                          quantity: 1,
                          modifiers: [],
                        };
                      });
                      modifier.push(...checkboxModifier);
                    } else {
                      modifierDetails = {
                        // ...store_menu?.modifierGroups?.[modifierKey],
                        groupId: store_menu?.modifierGroups?.[modifierKey].id,
                        groupName:
                          store_menu?.modifierGroups?.[modifierKey].name,
                        id: store_menu?.items?.[modifierValue].id,
                        name: store_menu?.items?.[modifierValue].name,
                        price: store_menu?.items?.[modifierValue].price.amount,
                        quantity: 1,
                        modifiers: [],
                      };
                      modifier.push(modifierDetails);
                    }
                  }
                  singleItem.push({
                    id: item.id,
                    name: item.name,
                    quantity: 1,
                    // note: item.modifiersWithNote.map((e) => e.note).join('|'),
                    note: item.modifiersWithNote.map((e) => e.note).join(''),
                    price: item.price.amount,
                    modifiers: [...modifier],
                  });
                });
                return singleItem;
                // return {
                //   id: item.id,
                //   name: item.name,
                //   quantity: 1,
                //   // note: item.modifiersWithNote.map((e) => e.note).join('|'),
                //   note: item.modifiersWithNote.map((e) => e.note).join(''),
                //   price: item.price.amount,
                //   // modifiers: [...modifier],
                //   modifiers: [],
                // };
              }
            });

            let newCartItemArray = [];
            formattedItems.forEach((SI, index) => {
              if (Array.isArray(SI)) {
                SI.forEach((si) => newCartItemArray.push(si));
              } else {
                newCartItemArray.push(SI);
              }
            });
            var myHeaders = new Headers();
            myHeaders.append('x-api-key', 'test-key');
            myHeaders.append('Content-Type', 'application/json');

            let today = new Date();
            // today = today.toISOString().split('.')[0];
            today.setMilliseconds(0);
            today = today.toISOString().replace('.000Z', 'Z');

            var raw = {
              call_type: 'CONFIRM_ORDER', // const variable -- hard code it
              data: {
                metadata: {
                  store_id: store_details.brand_id,
                  order_type: 'Wa', // const variable -- hard code it
                  payment_gateway_order_id: razorpay_order_id,
                  payment_gateway_payment_id: razorpay_payment_id,
                  payment_gateway_signature: razorpay_signature,
                  payment_gateway_receipt_number: receipt,
                },

                order: {
                  items: [...newCartItemArray],
                  // orderedAt: new Date(),
                  orderedAt: today,
                  currencyCode: store_details.store_currency,
                  customer: {
                    name: selectedAddress.name,
                    phone: `91${selectedAddress.phone}`,
                    email: '',
                  },
                  status: 'NEW_ORDER', // keep it same it will be static
                  deliveryInfo: {
                    destination: {
                      postalCode: selectedAddress?.pinCode,
                      city: selectedAddress?.city,
                      state: selectedAddress?.state,
                      countryCode: 'IND',
                      addressLines: [
                        selectedAddress?.address1,
                        selectedAddress?.address2,
                      ],
                      location: {
                        latitude: '',
                        longitude: '',
                      },
                    },
                    lastKnownLocation: {
                      latitude: '',
                      longitude: '',
                    },
                    note: note, // delivery note
                  },
                  customerNote: orderNote, // Summary page "order note" will go here
                  orderTotal: {
                    ...orderTotal,
                  },
                  customerPayments: [
                    // keep it same. it will be same as below
                    {
                      value: 0,
                      processingStatus: 'PROCESSED',
                      paymentMethod: 'CARD',
                    },
                  ],
                  fulfillmentInfo: {
                    fulfillmentMode: deliveryMode.toUpperCase(),
                  },
                },
              },
            };

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify(raw),
              redirect: 'follow',
            };

            fetch(process.env.REACT_APP_ORDER_URL, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                history.push({
                  pathname: `/placeaOrder`,
                  search: `?brand_id=${store_details.brand_id}`,
                  state: { serverResponse: JSON.parse(result) },
                });
                // setShowModal(true);
              })
              .catch((error) => console.log('error', error));
          },
        };

        const rzp1 = new window.Razorpay(razorpayOptions);
        rzp1.open();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('@userInfo'));
    setSavedUserInfo(userInfo);
    if (userInfo) {
      let savedAddresses = Object.keys(userInfo);
      if (savedAddresses?.length === 1) {
        setSelectedAddress({
          ...userInfo[savedAddresses[0]],
          key: savedAddresses[0],
        });
      } else {
        let address;
        Object.keys(userInfo).map((tagKey, index) => {
          if (userInfo[tagKey].selected) {
            address = { ...userInfo[tagKey], key: tagKey };
          }
        });
        setSelectedAddress(address);
      }
    }
  }, []);

  return (
    <div className='ProcessToPayWrapper'>
      <div className='totalOrder'>
        <p>Total</p>
        <p>
          {' '}
          {currencySymbol(store_details.store_currency)} {cart.total}
        </p>
      </div>
      <div className='addressWrap'>
        <div className='addressHeading'>
          <h5>
            Address<sup>*</sup>
          </h5>
          <a
            onClick={() => {
              setOpenAddress(true);
            }}>
            + Add Address
          </a>
        </div>
        {savedUserInfo ? (
          Object.keys(savedUserInfo).map((key, index) => {
            return (
              <div
                key={key}
                className={`ProcessToPay__addresses ${
                  // (key === selectedAddress?.key && 'selected') ||
                  savedUserInfo[key].selected && 'selected'
                }`}
                onClick={() => {
                  handleSelectedAddress({ ...savedUserInfo[key], key });
                }}>
                {key}
                <br />
                {savedUserInfo[key].name}
                <br />
                {savedUserInfo[key].phone}
                <br />
                {savedUserInfo[key].address1} {savedUserInfo[key].address2}{' '}
                <br />
                {savedUserInfo[key].city} {savedUserInfo[key].state}{' '}
                {savedUserInfo[key].country} {savedUserInfo[key].pinCode}
                <img
                  src={RightArrow}
                  alt='Right arrow'
                  width={24}
                  height={24}
                />
              </div>
            );
          })
        ) : (
          <div className='ProcessToPay__addresses notSelected'>
            Add address to proceed
          </div>
        )}
      </div>
      <div className='addressWrap'>
        <div className='addressHeading'>
          <h5>Delivery Instructions</h5>
        </div>
        <textarea
          placeholder='Add delivery instructions here'
          rows='4'
          onChange={({ target }) => setNote(target.value)}></textarea>
      </div>
      <div className='bottomfixbtn'>
        <button disabled={!selectedAddress} onClick={handlePayment}>
          Proceed to pay
        </button>
      </div>
      {openAddress ? (
        <div className='AddAddress'>
          <div className='closeicon'>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => setOpenAddress(false)}
            />
          </div>
          <div className='AddAddressform'>
            <div className='input'>
              <input
                type='text'
                placeholder='Name*'
                name='name'
                onChange={onChangeHandler}
                value={inputs.name}
              />
            </div>
            <div className='input'>
              <input
                // type='number'
                placeholder='Phone Number*'
                name='phone'
                onChange={onChangeHandler}
                value={inputs.phone}
                type='text'
                maxlength='10'
                pattern='\d{10}'
              />
            </div>
            <div className='input'>
              <input
                type='text'
                placeholder='Address Line 1*'
                name='address1'
                onChange={onChangeHandler}
                value={inputs.address1}
              />
            </div>
            <div className='input'>
              <input
                type='text'
                placeholder='Address Line 2*'
                name='address2'
                onChange={onChangeHandler}
                value={inputs.address2}
              />
            </div>
            <div className='input'>
              <input
                type='text'
                placeholder='Landmark (Optional)'
                name='landmark'
                onChange={onChangeHandler}
                value={inputs.landmark}
              />
            </div>
            <div className='inputtwo'>
              <div className='input'>
                <input
                  type='text'
                  placeholder='City'
                  name='city'
                  onChange={onChangeHandler}
                  value={inputs.city}
                />
              </div>
              <div className='input'>
                <input
                  type='text'
                  placeholder='State'
                  name='state'
                  onChange={onChangeHandler}
                  value={inputs.state}
                />
              </div>
            </div>
            <div className='inputtwo'>
              <div className='input'>
                <input
                  type='text'
                  placeholder='Country'
                  name='Country'
                  defaultValue={store_details.store_country}
                  disabled
                  // onChange={onChangeHandler}
                  // value={inputs.country}
                />
              </div>
              <div className='input'>
                <input
                  type='number'
                  placeholder='Pincode'
                  name='pinCode'
                  onChange={onChangeHandler}
                  value={inputs.pinCode}
                />
              </div>
            </div>
            <div className='selectTag'>
              <p>
                TAG<sup>*</sup>
              </p>
              <button onClick={() => setInputs({ ...inputs, tag: 'Home' })}>
                Home
              </button>
              <button onClick={() => setInputs({ ...inputs, tag: 'Office' })}>
                Office
              </button>
              <button onClick={() => setInputs({ ...inputs, tag: 'Other' })}>
                Others
              </button>
            </div>
          </div>
          <div className='AddAddressBtn'>
            <button onClick={handleClick}>Add Address</button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProcessToPay;
