/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import './AllCategories.css';

import Categoriesimage from '../../Assets/Images/categoriesimage.png';

import { CategoryContext } from '../../contextAPIs/category.context';
import { Link } from 'react-router-dom';

const AllCategories = () => {
  const { push } = useHistory();
  const {
    store_menu: { photos, categories, items },
  } = useContext(CategoryContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='categoriesmenuWrapper'>
        <div className='categoriesTitle'>
          <h5>All Categories</h5>
        </div>
        <div className='allCategoriesSection'>
          <ul>
            {Object.keys(categories).map((key, i) => (
              <li>
                <Link key={i} to={`/#${categories[key].id}`}>
                  <img
                    src={
                      photos[items[categories[key].itemIds[0]].photoIds[0]]
                        ?.url || Categoriesimage
                    }
                    alt={
                      photos[items[categories[key].itemIds[0]].photoIds[0]]
                        ?.fileName || 'Categoriesimage'
                    }
                  />
                  <h6>{categories[key].name}</h6>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='bottomfixbtn'>
        <button onClick={() => push('/')}>Go Back</button>
      </div>
    </div>
  );
};

export default AllCategories;
