/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Item1 from '../../Assets/Images/item1.png';
import vegetarian from '../../Assets/Images/vegetarian.png';
import menu from '../../Assets/Images/menu.png';
import './index.css';
import { CategoryContext } from '../../contextAPIs/category.context';
import { currencySymbol } from '../../Helper/currencyHelper';
import AddToCart from '../../Components/AddToCart';
import useAddToCart from '../../hooks/useAddToCart';
import { Card } from 'react-bootstrap';

const Menu = (props) => {
  let history = useHistory();
  const {
    store_details: { store_currency, brand_id },
    store_menu: { photos, categories, items },
    setCategoryData,
  } = useContext(CategoryContext);

  const { myCartValue, addToCart, removeFromCart, totalCartItems } =
    useAddToCart(setCategoryData);
  const [more, setMore] = useState(true);

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document
        .getElementById(hash.substr(1))
        .scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.history.location.hash]); // Fires when component mounts and every time hash changes

  const [renderParent, setRenderParent] = useState(); //! This is not good practice. We are forcing parent to re-render. Use REDUX

  return (
    <div className='menuWrapper'>
      {Object.keys(categories).map((key, i) => (
        <div
          id={categories[key].id}
          key={`${i}-menu`}
          className='menudetailMain'>
          <h3 className='menu_heading'>{categories[key].name}</h3>
          {categories[key].itemIds.map((itemKey, i) => (
            <div key={i} className='menuDetails'>
              <div className='menuList'>
                <div className='menuinfo'>
                  <div
                    className={
                      items[itemKey].description ? 'itemList' : 'noDisscription'
                    }>
                    <div className='leftItemlist'>
                      <div style={{ display: 'flex', gap: 15 }}>
                        <h6>{items[itemKey].name}</h6>
                        {/* <img src={vegetarian} alt='veg' /> */}
                      </div>
                      <p>
                        {more
                          ? items[itemKey].description
                              .split(' ')
                              .slice(0, 15)
                              .join(' ')
                          : items[itemKey].description}
                        <a
                          style={{ color: '#ff9800', cursor: 'pointer' }}
                          onClick={() => setMore(!more)}>
                          {' '}
                          {items[itemKey].description.split(' ').length > 15
                            ? more
                              ? 'more..'
                              : 'Less'
                            : ''}
                        </a>
                      </p>
                    </div>
                    <div className='itemPrice'>
                      {/* <img src={vegetarian} alt='veg' /> */}
                      <h2 style={{ margin: 0 }}>
                        {currencySymbol(store_currency)}{' '}
                        {items[itemKey].price.amount}
                      </h2>
                    </div>
                  </div>
                </div>
                {photos[items[itemKey].photoIds]?.url ? (
                  <div className='menuimage'>
                    <div className='itemImage'>
                      <div
                        style={{
                          backgroundImage: `url(${
                            photos[items[itemKey].photoIds]?.url
                          })`,
                          width: '75px',
                          height: '75px',
                          borderRadius: '5px',
                          backgroundSize: 'cover',
                        }}></div>
                      {/* <Card.Img
                        variant='top'
                        // style={{ objectFit: 'cover' }}
                        style={{ color: 'red' }}
                        src={photos[items[itemKey].photoIds]?.url}
                      /> */}
                      {/* <img
                        src={photos[items[itemKey].photoIds]?.url}
                        // width='75px'
                        // height='75px'
                        alt='pizza'
                      /> */}
                      <AddToCart
                        addToCart={addToCart}
                        removeFromCart={removeFromCart}
                        myCartValue={myCartValue(itemKey)}
                        itemKey={itemKey}
                        brandId={brand_id}
                        modifierGroupLength={
                          items[itemKey].modifierGroupIds.length
                        }
                        setRenderParent={setRenderParent}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='menuimage'>
                    <div className='itemImage'>
                      <img src={Item1} alt='pizza' />
                      <AddToCart
                        addToCart={addToCart}
                        removeFromCart={removeFromCart}
                        myCartValue={myCartValue(itemKey)}
                        itemKey={itemKey}
                        brandId={brand_id}
                        modifierGroupLength={
                          items[itemKey].modifierGroupIds.length
                        }
                        setRenderParent={setRenderParent}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
      <div className='menuBar'>
        <div
          className='menubottom'
          onClick={() => history.push(`/allCategories?brand_id=${brand_id}`)}>
          <img src={menu} alt='menuIcon' />
          <p>Menu</p>
        </div>
      </div>
      <div className='viewCart'>
        <button
          p
          disabled={totalCartItems() <= 0}
          onClick={() => history.push(`/placeaOrder?brand_id=${brand_id}`)}>
          VIEW CART ({totalCartItems()})
        </button>
      </div>
    </div>
  );
};

export default Menu;
