/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useHistory } from 'react-router';
import { CategoryContext } from '../../contextAPIs/category.context';
import { Link } from 'react-router-dom';
import './PlaceOrder.css';

import ModalPage from '../modal/ModalPage';
import { currencySymbol } from '../../Helper/currencyHelper';
import { getCartSummary } from '../../Helper/getCartSummary';
import { groupBy, isObjEmpty } from '../../Helper/helper';
import InfoIcon from '../../Assets/Images/infoIcon.jpg';

const PlaceOrder = ({ deliveryMode, ...props }) => {
  const { store_details, store_menu } = useContext(CategoryContext);
  const [cart, setCart] = useState({});
  const [renderItems, setRenderItems] = useState([]);
  const [orderNote, setOrderNote] = useState('');
  const [showModal, setShowModal] = useState(false);
  let serverResponse = props?.location?.state?.serverResponse || {};
  useEffect(() => {
    if (serverResponse?.message?.message || serverResponse?.error_message)
      setShowModal(true);
    return () => {};
  }, [serverResponse?.error_message, serverResponse?.message?.message]);

  const history = useHistory();

  useEffect(() => {
    const cartSummary = getCartSummary({
      store_details,
      store_menu,
      deliveryMode,
    });
    setCart(cartSummary);
  }, [deliveryMode, store_details, store_menu]);

  useEffect(() => {
    const checkCart = isObjEmpty(cart);
    if (checkCart) return;
    // TODO: This function is being repeat many times as we are formatting data in many component. Take this logic in REDUX
    // TODO: This is also being used in ProcessToPay page

    // * This is correct function and latest than ProceedToPay
    let formattedItems = cart?.items?.map((item, index) => {
      if (
        item.modifiersWithNote[0].modifier &&
        Object.keys(item.modifiersWithNote[0].modifier).length === 0 &&
        Object.getPrototypeOf(item.modifiersWithNote[0].modifier) ===
          Object.prototype
      ) {
        return {
          id: item.id,
          name: item.name,
          quantity: item.modifiersWithNote.length,
          // note: item.modifiersWithNote.map((e) => e.note).join('|'),
          note: item.modifiersWithNote.map((e) => e.note).join(''),
          price: item.price.amount,
        };
      } else {
        let singleItem = [];
        item.modifiersWithNote.forEach((element) => {
          let modifier = [];
          let modifierDetails;
          for (const [modifierKey, modifierValue] of Object.entries(
            element?.modifier
          )) {
            if (Array.isArray(modifierValue)) {
              let checkboxModifier = modifierValue.map((md) => {
                return {
                  // ...store_menu?.items?.[md],
                  groupId: store_menu?.modifierGroups?.[modifierKey].id,
                  groupName: store_menu?.modifierGroups?.[modifierKey].name,
                  id: store_menu?.items?.[md].id,
                  name: store_menu?.items?.[md].name,
                  price: store_menu?.items?.[md].price.amount,
                  quantity: 1,
                  modifiers: [],
                };
              });
              modifier.push(...checkboxModifier);
            } else {
              modifierDetails = {
                // ...store_menu?.modifierGroups?.[modifierKey],
                groupId: store_menu?.modifierGroups?.[modifierKey].id,
                groupName: store_menu?.modifierGroups?.[modifierKey].name,
                id: store_menu?.items?.[modifierValue].id,
                name: store_menu?.items?.[modifierValue].name,
                price: store_menu?.items?.[modifierValue].price.amount,
                quantity: 1,
                modifiers: [],
              };
              modifier.push(modifierDetails);
            }
          }
          singleItem.push({
            id: item.id,
            name: item.name,
            quantity: 1,
            // note: item.modifiersWithNote.map((e) => e.note).join('|'),
            note: item.modifiersWithNote.map((e) => e.note).join(''),
            price: item.price.amount,
            modifiers: [...modifier],
          });
        });
        return singleItem;
        // return {
        //   id: item.id,
        //   name: item.name,
        //   quantity: 1,
        //   // note: item.modifiersWithNote.map((e) => e.note).join('|'),
        //   note: item.modifiersWithNote.map((e) => e.note).join(''),
        //   price: item.price.amount,
        //   // modifiers: [...modifier],
        //   modifiers: [],
        // };
      }
    });

    let newCartItemArray = [];
    formattedItems.forEach((SI, index) => {
      if (Array.isArray(SI)) {
        SI.forEach((si) => newCartItemArray.push(si));
      } else {
        newCartItemArray.push(SI);
      }
    });
    const groupByName = groupBy('groupName');
    let itemsWithGroupedModifiers = newCartItemArray.map(
      (item, index) => {
        if (item?.modifiers)
          return { ...item, groupedModifier: groupByName(item.modifiers) };
        else return item;
      }
      //
    );

    setRenderItems(itemsWithGroupedModifiers);
  }, [cart, store_menu?.items, store_menu?.modifierGroups]);

  const renderTaxTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      GST = CGST ({store_details.store_tax_rate / 2}%) + SGST (
      {store_details.store_tax_rate / 2}%){' '}
    </Tooltip>
  );

  // TODO: Ths function is being used in Header page as well so don't write it twice so it is better to make the tooltip a separate component
  //* Keep currency symbol in REDUX store as well

  const discountTooltip = (storeDetails, subTotal) => {
    let fixedDiscount = Number(storeDetails?.store_discount_amount);
    let percentagedDiscount = Number(storeDetails?.store_discount_percent);

    if (fixedDiscount) {
      return `Discount of ${currencySymbol(
        store_details.store_currency
      )}${fixedDiscount} on min bill of ${
        storeDetails?.store_discount_min_bill
      }`;
    } else if (percentagedDiscount) {
      return `Discount of ${percentagedDiscount}% upto max of ${currencySymbol(
        store_details.store_currency
      )}${
        storeDetails?.store_discount_max_amount
      } on min bill of ${currencySymbol(store_details.store_currency)} ${
        storeDetails?.store_discount_min_bill
      }`;
    }
  };
  const renderDiscountTooltip = (props) => {
    const discount = discountTooltip(store_details, cart.subtotal);
    return (
      <Tooltip id='button-tooltip-discount' {...props}>
        {discount}
      </Tooltip>
    );
  };
  // TODO: ------------------------------------------------

  const selectedModifierValues = (modifier, name = true) => {
    return modifier.map((mod, index) => {
      return (
        <>
          <div className='selectedModifierValues'>
            <p> {name ? mod.name : ' '}</p>
            <p>
              {!name ? currencySymbol(store_details.store_currency) : ''}
              {!name ? mod.price : ''}
            </p>
          </div>
        </>
      );
    });
  };

  const renderModifier = (item, name) => {
    const { groupedModifier } = item;
    return Object.keys(groupedModifier)?.map((modifierName, index) => {
      return (
        <>
          <div className='placeOrder__groupedModifier'>
            {/* <p className='modifierName'>{name ? modifierName : ' '}</p> */}
            {name ? (
              <p className='modifierName'>{modifierName}</p>
            ) : (
              <p>&nbsp;</p>
            )}
            {/* {!name && <br />} */}
            {/* <p
              style={
                {
                  // visibility: name ? 'visible' : 'hidden',
                  // width: name ? 'auto' : '0px',
                }
              }>
              {modifierName}
            </p> */}
            {selectedModifierValues(groupedModifier[modifierName], name)}
          </div>
        </>
      );
    });
  };

  // const renderModifierPrice = (item, name) => {
  //   const { groupedModifier } = item;
  //   return Object.keys(groupedModifier)?.map((modifierName, index) => {
  //     return (
  //       <>
  //         <div className='placeOrder__groupedModifier'>
  //           <p className='modifierName'>{name && modifierName}</p>
  //           {selectedModifierValues(groupedModifier[modifierName], name)}
  //         </div>
  //       </>
  //     );
  //   });
  // };
  return (
    <>
      <div className='YourOrderWrapper'>
        <div className='YourOrderHeading'>
          <h5>Your Order Summary</h5>
          {!serverResponse?.message && !serverResponse?.error_message && (
            <Link
              to={{
                pathname: '/',
                search: `?brand_id=${store_details.brand_id}`,
              }}>
              Add items +
            </Link>
          )}
        </div>
        <div className='YourOrderInfo'>
          <ul>
            {/* {cart?.items?.map((item, index) => {
              return (
                <li className='item'>
                  <div className='item_info'>
                    <p className='item_quantity'>{item.quantity}</p>
                    <div className='itemName'>
                      <p>{item.name}</p>
                      <span>{item.description}</span>
                    </div>
                  </div>
                  <div className='item_price'>
                    <p>
                      {currencySymbol(item.price.currencyCode)}
                      {item.price.amount * item.quantity + item.modifierPrice}
                    </p>
                  </div>
                </li>
              );
            })} */}
            {renderItems?.map((item, index) => {
              return (
                <li className='item'>
                  <div className='item_info'>
                    <p className='item_quantity'>{item.quantity}</p>
                    <div className='itemName'>
                      <p>{item.name}</p>
                      {item.groupedModifier && renderModifier(item, true)}
                    </div>
                  </div>
                  <div className='item_price'>
                    <p>
                      {currencySymbol(store_details.store_currency)}
                      {item.price * item.quantity}
                    </p>
                    {item.groupedModifier && renderModifier(item, false)}
                  </div>

                  {/* <ul>
                    <li className='item'>
                      <div className='item_info'>
                        <p className='item_quantity'>{item.quantity}</p>
                        <div className='itemName'>
                          <p>{item.name}</p>
                        </div>
                      </div>
                      <div className='item_price'>
                        <p>
                          {currencySymbol(store_details.store_currency)}
                          {item.price}
                        </p>
                      </div>
                    </li>
                  </ul> */}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className='SummaryWrap'>
        {/* <div className='SummaryHeading'>
          <h5>Summary</h5>
        </div> */}
        <div className='SummaryMain'>
          <ul>
            <li>
              <p className='title'>Subtotal</p>
              <p className='rate'>
                {currencySymbol(store_details.store_currency)} {cart.subtotal}
              </p>
            </li>
            <li>
              <OverlayTrigger
                placement='right'
                delay={{ show: 250, hide: 400 }}
                // overlay={() => (
                //   <RenderDiscountTooltip subtotal={cart.subtotal} />
                // )}>
                overlay={renderDiscountTooltip}>
                <p className='title'>
                  Discount <img src={InfoIcon} alt='info' width={10} />
                </p>
              </OverlayTrigger>
              <p className='rate discount'>
                - {currencySymbol(store_details.store_currency)} {cart.discount}
              </p>
            </li>
            {deliveryMode === 'delivery' && (
              <li>
                <p className='title'>Delivery Fee</p>
                <p className='rate'>
                  {currencySymbol(store_details.store_currency)}{' '}
                  {cart.deliveryFee}
                </p>
              </li>
            )}

            <li>
              {/* <OverlayTrigger
                placement='right'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTaxTooltip}>
                <p className='title'>
                  Taxes <img src={InfoIcon} alt='info' width={10} />
                </p>
              </OverlayTrigger> */}
              <p className='title'>Taxes</p>
              <p className='rate'>
                {currencySymbol(store_details.store_currency)} {cart.tax}
              </p>
            </li>
            <li className='total'>
              <p className='title'>Total</p>
              <p className='rate'>
                {currencySymbol(store_details.store_currency)} {cart.total}
              </p>
            </li>
          </ul>

          {!serverResponse?.message && !serverResponse?.error_message && (
            <div>
              <h5>Order Notes</h5>
              <textarea
                onChange={({ target }) => setOrderNote(target.value)}
                placeholder='Additional notes...'
                rows='4'
                className='order__note-textArea'></textarea>
            </div>
          )}

          <div className='item-add__bottom__margin' />
        </div>
      </div>
      {!serverResponse?.message && !serverResponse?.error_message && (
        <div className='bottomfixbtn'>
          <button
            onClick={() => {
              // history.push(`/processToPay?brand_id=${store_details.brand_id}`);
              history.push({
                pathname: `/processToPay`,
                search: `?brand_id=${store_details.brand_id}`,
                state: { orderNote },
              });
            }}>
            place order
          </button>
        </div>
      )}
      <ModalPage
        setShowModal={setShowModal}
        showModal={showModal}
        message={
          (serverResponse?.message && serverResponse) ||
          serverResponse?.error_message
        }
      />
    </>
  );
};

export default PlaceOrder;
