import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Menu from './Pages/Menu/index';
import AllCategories from './Pages/allCategories/AllCategories';
import Header from './Pages/header/Header';
import Item from './Pages/item/Item';
import PlaceOrder from './Pages/placeOrder/PlaceOrder';
import ProcessToPay from './Pages/processToPay/ProcessToPay';
import RazorPay from './Pages/razorPay/RazorPay';
import { CategoryContext } from './contextAPIs/category.context';
import data from '../src/store_menu_details.json';
import { get } from 'axios';
import { app_endpoint } from './Helper/axiosConfig';
import { getQueryVariable } from './Helper/helper';
import Alert from 'react-bootstrap/Alert';

import { myCart } from './Constants/localStorageConst';
function App() {
  const [categoryData, setCategoryData] = useState(data);
  const [show, setShow] = useState(false);
  const [deliveryMode, setDeliveryMode] = useState('delivery');
  const [isRestaurantExists, setIsRestaurantExists] = useState(true);
  const setCategoryContextData = async (fromApi = false, brandId) => {
    try {
      if (fromApi) {
        const url = `${app_endpoint.getAllCategoryEndPoint}/${brandId}`;
        const newData = await get(url);
        setCategoryData({ ...newData.data });
      } else {
        setCategoryData({ ...categoryData });
      }
    } catch (error) {
      if (error.response) {
        //? axios error
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.data.code === 404) setIsRestaurantExists(false);
      }
    }
  };

  useEffect(() => {
    const brandId = getQueryVariable('brand_id');
    if (!brandId) {
      setShow(true);
    } else {
      setCategoryContextData(true, brandId);
    }
    window.onbeforeunload = () => {
      if (localStorage.getItem(myCart)) {
        localStorage.removeItem(myCart);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = categoryData?.store_details.store_name;
  }, [categoryData.store_details.store_name]);

  // TODO: Make it one
  if (show) {
    return (
      <div className='App-ErrorModal'>
        <Alert variant='danger'>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>Please Provide a brand id before continuing.</p>
        </Alert>
      </div>
    );
  }
  if (!isRestaurantExists) {
    return (
      <div className='App-ErrorModal'>
        <Alert variant='danger'>
          <Alert.Heading>
            Sorry restaurant with this brand id does not exist
          </Alert.Heading>
          {/* <p>Sorry restaurant with this brand id does not exist</p> */}
        </Alert>
      </div>
    );
  }
  // TODO --------------------------------------------
  return (
    <div className='App'>
      <CategoryContext.Provider
        value={{ ...categoryData, setCategoryData: setCategoryContextData }}>
        <Router>
          <Header
            deliveryMode={deliveryMode}
            setDeliveryMode={setDeliveryMode}
          />
          <Switch>
            <Route exact path='/' component={Menu} />
            <Route exact path='/allCategories' component={AllCategories} />
            <Route path='/item/:itemID' component={Item} />
            <Route
              exact
              path='/placeaOrder'
              render={(props) => (
                <PlaceOrder deliveryMode={deliveryMode} {...props} />
              )}
            />
            <Route
              exact
              path='/processToPay'
              render={(props) => (
                <ProcessToPay deliveryMode={deliveryMode} {...props} />
              )}
            />
            <Route exact path='/razorPay' component={RazorPay} />
          </Switch>
        </Router>
      </CategoryContext.Provider>
    </div>
  );
}

export default App;
