import { useCallback } from 'react';
import { getMyCart } from '../Constants';
import { myCart, setItemToMyCart } from '../Constants/localStorageConst';

const useAddToCart = (setCategoryData) => {
  const addToCart = useCallback((itemKey, note = '', modifier = {}) => {
    const cart = getMyCart(myCart, '{}');
    const newCart = {
      ...cart,
      [itemKey]: [
        ...(cart[itemKey] ?? []),
        { note: note ?? '', modifier: { ...modifier } ?? {} },
      ],
    };
    setItemToMyCart(newCart, {});
  }, []);

  const removeFromCart = useCallback((itemKey) => {
    const cart = getMyCart(myCart, '{}');
    cart[itemKey]?.pop();
    setCategoryData();
    setItemToMyCart(cart, {});
  }, []);

  const myCartValue = useCallback(
    (itemKey) => {
      const cart = getMyCart(myCart, '{}');
      return cart[itemKey]?.length ? cart[itemKey].length : false;
    },
    [addToCart, removeFromCart]
  );

  const totalCartItems = () => {
    const cart = getMyCart(myCart, '{}');
    let totalItems = 0;
    for (const [key, value] of Object.entries(cart)) {
      totalItems = value?.length + totalItems;
    }
    return totalItems;
  };

  return {
    myCartValue,
    addToCart,
    removeFromCart,
    totalCartItems,
  };
};

export default useAddToCart;
