// eslint-disable-next-line import/no-anonymous-default-export
export default function (axios) {
  const { REACT_APP_BACKEND_URL, REACT_APP_BACKEND_AUTH_TOKEN } = process.env;

  axios.defaults.baseURL = REACT_APP_BACKEND_URL;

  axios.interceptors.request.use((req) => {
    req.headers = { Authorization: REACT_APP_BACKEND_AUTH_TOKEN };
    return req;
  }, Promise.reject);
}

export const app_endpoint = {
  getAllCategoryEndPoint: '/_fabric/_system/_api/document/store-data',
};
