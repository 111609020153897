/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './Item.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CategoryContext } from '../../contextAPIs/category.context';
import useAddToCart from '../../hooks/useAddToCart';
import { currencySymbol } from '../../Helper/currencyHelper';

const Item = () => {
  let requiredFields = {};
  const { push } = useHistory();
  const [note, setNote] = useState();
  const { itemID } = useParams();
  const { store_menu, store_details } = useContext(CategoryContext);
  const { addToCart } = useAddToCart();

  const [radioInputValues, setRadioInputValues] = useState({});
  const [checkboxInputValues, setCheckboxInputValues] = useState({});
  const onPlaceOrder = () => {
    let modifier = { ...radioInputValues, ...checkboxInputValues };
    const rF = Object.keys(requiredFields).map((key) => {
      return modifier.hasOwnProperty(key);
    });
    if (rF.indexOf(false) === -1) {
      console.log("element doesn't exist");
    } else {
      // console.log('Please fill all required Fields');
      return;
    }
    addToCart(itemID, note, modifier);
    push(`/?brand_id=${store_details.brand_id}`);
  };

  const handleRadioChange = ({ target: { name, value } }) => {
    setRadioInputValues({
      ...radioInputValues,
      [name]: value,
      // name,
      // value,
    });
  };
  const handleCheckboxChange = ({ target: { name, value } }) => {
    let checkbox = { ...checkboxInputValues };

    if (!checkbox[name]) checkbox[name] = [];
    if (!checkbox[name].includes(value)) {
      checkbox[name].push(value);
    } else {
      checkbox[name].splice(checkbox[name].indexOf(value), 1);
    }
    setCheckboxInputValues(checkbox);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className='CategoryWrapper'>
        <div className='Categoryheading'>
          <h5>{store_menu.items[itemID].name}</h5>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => window.history.back()}
          />
        </div>
        <div className='Categoryorderinfo'>
          {store_menu.items[itemID].modifierGroupIds.length ? (
            <div>
              {store_menu.items[itemID].modifierGroupIds.map(
                (modifier, index) => {
                  const maximumSelections =
                    store_menu.modifierGroups[modifier].maximumSelections;

                  if (maximumSelections > 1)
                    return (
                      <div className='ChooseSize SelectCrust Addon'>
                        <div className='ChooseSizeHeading'>
                          <h6>{store_menu.modifierGroups[modifier].name}</h6>
                        </div>
                        <div className='ChooseSizeForm'>
                          <ul>
                            {store_menu.modifierGroups[modifier].itemIds.map(
                              (itemId, index) => {
                                return (
                                  <>
                                    <li
                                      key={`checkbox--${index}-${store_menu.modifierGroups[modifier].name}`}>
                                      <label class='radiocustom'>
                                        <div className='radiocustomtext'>
                                          <p>{store_menu.items[itemId].name}</p>
                                          <span>
                                            {currencySymbol(
                                              store_menu.items[itemId].price
                                                .currencyCode
                                            )}
                                            {
                                              store_menu.items[itemId].price
                                                .amount
                                            }
                                          </span>
                                        </div>
                                        <input
                                          type='checkbox'
                                          // name={
                                          //   store_menu.modifierGroups[modifier]
                                          //     .name
                                          // }
                                          name={
                                            store_menu.modifierGroups[modifier]
                                              .id
                                          }
                                          value={itemId}
                                          onChange={handleCheckboxChange}
                                        />
                                        <span class='checkmarkcustom'></span>
                                      </label>
                                    </li>
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    );
                  else if (maximumSelections === 1)
                    requiredFields[
                      store_menu.modifierGroups[modifier].id
                    ] = true;
                  return (
                    <div className='ChooseSize'>
                      <div className='ChooseSizeHeading'>
                        <h6>{store_menu.modifierGroups[modifier].name}</h6>
                        <button className='requiredBtn'>Required</button>
                      </div>
                      <div className='ChooseSizeForm'>
                        <ul>
                          {store_menu.modifierGroups[modifier].itemIds.map(
                            (itemId, index) => {
                              return (
                                <>
                                  <li
                                    key={`radiobutton--${index}-${store_menu.modifierGroups[modifier].name}`}>
                                    <label class='radiocustom'>
                                      <div className='radiocustomtext'>
                                        <p>{store_menu.items[itemId].name}</p>
                                        <span>
                                          {currencySymbol(
                                            store_menu.items[itemId].price
                                              .currencyCode
                                          )}
                                          {
                                            store_menu.items[itemId].price
                                              .amount
                                          }
                                        </span>
                                      </div>
                                      <input
                                        type='radio'
                                        name={
                                          store_menu.modifierGroups[modifier].id
                                        }
                                        value={itemId}
                                        onChange={handleRadioChange}
                                      />
                                      <span class='checkmarkcustom'></span>
                                    </label>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            ''
          )}
          <div className='item-add__bottom__margin' />
          {/* <textarea
            onChange={({ target }) => setNote(target.value)}
            placeholder='Additional notes...'
            rows='4'
            className='item-textArea'></textarea> */}
        </div>
      </div>
      <div className='bottomfixbtn__confirmItem'>
        <button onClick={onPlaceOrder}>Add to cart</button>
      </div>
    </div>
  );
};

export default Item;
