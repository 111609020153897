import { useHistory } from 'react-router';

const AddToCart = ({
  itemKey,
  myCartValue,
  addToCart,
  removeFromCart,
  brandId,
  modifierGroupLength,
  setRenderParent,
}) => {
  const { push } = useHistory();

  const handleSimpleItem = () => {
    addToCart(itemKey);
    setRenderParent(new Date()); //! This is not good practice. We are forcing parent to re-render. Use REDUX
  };

  const handleComplexItem = () => {
    push(`/item/${itemKey}?brand_id=${brandId}`);
  };

  if (!myCartValue) {
    return (
      <button
        onClick={
          modifierGroupLength > 0 ? handleComplexItem : handleSimpleItem
        }>
        {' '}
        + ADD
      </button>
    );
  }

  return (
    <button
      style={{
        display: 'flex',
        padding: '1px 6px',
        justifyContent: 'space-between',
      }}>
      <div
        style={{ padding: '0px 5px' }}
        onClick={() => removeFromCart(itemKey)}>
        -
      </div>
      <div>{myCartValue}</div>
      <div
        style={{ padding: '0px 5px' }}
        onClick={
          modifierGroupLength > 0 ? handleComplexItem : handleSimpleItem
        }>
        +
      </div>
    </button>
  );
};

export default AddToCart;
