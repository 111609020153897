import { getMyCart } from '../Constants';
import { myCart } from '../Constants/localStorageConst';

const discount = (storeDetails, subTotal) => {
  let fixedDiscount = Number(storeDetails?.store_discount_amount);
  let percentagedDiscount = Number(storeDetails?.store_discount_percent);

  if (subTotal > Number(storeDetails?.store_discount_min_bill)) {
    if (fixedDiscount >= 0) {
      return fixedDiscount;
    } else if (percentagedDiscount) {
      let discountAmount = (subTotal * percentagedDiscount) / 100;
      if (discountAmount < storeDetails?.store_discount_max_amount)
        return discountAmount;
      else return storeDetails?.store_discount_max_amount;
    }
  } else return 0;
};

export const getCartSummary = (data) => {
  const { store_details, store_menu, deliveryMode } = data;
  const selectedItems = getMyCart(myCart, '{}');
  let products = [];
  let subTotal = 0;
  for (const [key, value] of Object.entries(selectedItems)) {
    let modifierPrice = 0;
    if (
      value[0].modifier &&
      Object.keys(value[0].modifier).length === 0 &&
      Object.getPrototypeOf(value[0].modifier) === Object.prototype
    ) {
      // simple item
      modifierPrice = 0;
    } else {
      // complex item
      value.forEach((element) => {
        if (element?.modifier)
          for (const [modifierKey, modifierValue] of Object.entries(
            element?.modifier
          )) {
            if (Array.isArray(modifierValue)) {
              // console.log(
              //   'Checkbox Modifier Name',
              //   store_menu?.modifierGroups?.[modifierKey].name
              // );
              // console.log('Checkbox modifier Value', modifierValue);
              // eslint-disable-next-line no-loop-func
              modifierValue.forEach((md) => {
                modifierPrice =
                  modifierPrice + store_menu?.items?.[md].price.amount;
              });
            } else {
              // console.log(
              //   'Radio Modifier Name',
              //   store_menu?.modifierGroups?.[modifierKey].name
              // );
              // console.log(
              //   'Radio Modifier Value',
              //   store_menu?.items?.[modifierValue]
              // );
              modifierPrice =
                modifierPrice + store_menu?.items?.[modifierValue].price.amount;
            }
          }
        else {
          // console.log('value?.modifier ',element?.modifier);
        }
      });
    }

    products.push({
      ...store_menu?.items[key],
      // notes: [...value],
      modifiersWithNote: [...value],
      quantity: value?.length,
      modifierPrice,
    });
    subTotal =
      store_menu?.items[key].price.amount * value?.length +
      subTotal +
      modifierPrice;
  }

  const discountValue = discount(store_details, subTotal);
  const deliveryFee =
    deliveryMode === 'delivery' ? store_details?.store_delivery_fee : 0;
  const taxRate = store_details?.store_tax_rate;
  let taxAmount =
    (Number(subTotal) - Number(discountValue) + Number(deliveryFee)) *
    (Number(taxRate) / 100);
  taxAmount = Number(taxAmount.toFixed(2));

  let total =
    Number(subTotal) -
    Number(discountValue) +
    Number(deliveryFee) +
    Number(taxAmount);
  total = Number(total.toFixed(2));

  let cartSummary = {};
  cartSummary.items = products;
  cartSummary.subtotal = subTotal;
  cartSummary.discount = discountValue;
  if (deliveryFee > 0) cartSummary.deliveryFee = deliveryFee;
  cartSummary.tax = taxAmount;
  cartSummary.total = total;

  return cartSummary;
  // setCart(cartSummary);
};
