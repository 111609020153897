/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

// import banner from '../../Assets/Images/banner.png';
import deliveryMan from '../../Assets/Images/delivery.png';
import pickupMain from '../../Assets/Images/pickup.png';
import stopwatch from '../../Assets/Images/stopwatch1.png';
import discount1 from '../../Assets/Images/discount1.png';

import data from '../../store_menu_details.json';
import './Header.css';
import { CategoryContext } from '../../contextAPIs/category.context';
import { currencySymbol } from '../../Helper/currencyHelper';

const discount = (storeDetails) => {
  let fixedDiscount = storeDetails.store_discount_amount;
  let percentagedDiscount = storeDetails.store_discount_percent;
  if (fixedDiscount) {
    return `${currencySymbol(storeDetails.store_currency)}${fixedDiscount}`;
  } else if (percentagedDiscount) {
    return `${percentagedDiscount}%`;
  }
};

const Header = ({ deliveryMode, setDeliveryMode }) => {
  const { store_details, store_discount_amount, store_menu } =
    useContext(CategoryContext);
  const [menuTime, setMenuTime] = useState();

  useEffect(() => {
    const DAYS = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    const d = new Date();
    const day = DAYS[d.getDay()];
    const menus = Object.values(store_menu.menus);
    if (menus.length > 0) {
      let intervals = menus[0].hours.intervals;
      const matchedDay = intervals.filter((e) => e.day == day)[0];
      setMenuTime(
        `Menu Hours: ${matchedDay.fromHour}:${(
          '0' + matchedDay.fromMinute
        ).slice(-2)} to ${matchedDay.toHour}:${(
          '0' + matchedDay.toMinute
        ).slice(-2)}`
      );
    }
    return;
  }, [store_menu.menus]);
  console.log('store_details ');
  const item = ['DELIVERY'];
  const delivery = item.map((item, i) => (
    <div key={i} className='itemWrap'>
      <img src={deliveryMan} alt='delivery' />
      <li>{item}</li>
    </div>
  ));

  const itemup = ['PICK UP'];
  const pickup = itemup.map((itemup, i) => (
    <div key={i} className='itemWrap'>
      <img src={pickupMain} alt='pickupMain' />
      <li>{itemup}</li>
    </div>
  ));
  // TODO: Ths function is being used in PlaceOrder page as well so don't write it twice so it is better to make the tooltip a separate component
  const discountTooltip = (storeDetails) => {
    let fixedDiscount = Number(storeDetails?.store_discount_amount);
    let percentagedDiscount = Number(storeDetails?.store_discount_percent);

    if (fixedDiscount) {
      return `Discount of ${currencySymbol(
        store_details.store_currency
      )}${fixedDiscount} on min bill of ${
        storeDetails?.store_discount_min_bill
      }`;
    } else if (percentagedDiscount) {
      return `Discount of ${percentagedDiscount}% upto max of ${currencySymbol(
        store_details.store_currency
      )}${
        storeDetails?.store_discount_max_amount
      } on min bill of ${currencySymbol(store_details.store_currency)} ${
        storeDetails?.store_discount_min_bill
      }`;
    }
  };

  const renderDiscountTooltip = (props) => {
    const discount = discountTooltip(store_details);
    return (
      <Tooltip id='button-tooltip-discount' {...props}>
        {discount}
      </Tooltip>
    );
  };
  // TODO: ------------------------------------------------

  return (
    <div className='menuWrapper'>
      <div className='menuBanner'>
        <img src={store_details.store_image_url} alt='banner' />
      </div>

      <div className='deliveryMode'>
        <h2>{store_details.store_name}</h2>
        <div className='modeTab'>
          <Tabs
            defaultActiveKey='delivery'
            id='uncontrolled-tab-example'
            activeKey={deliveryMode}
            onSelect={(k) => setDeliveryMode(k)}>
            <Tab eventKey='delivery' title={delivery}>
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img src={stopwatch} alt='time' />
                  <p>
                    {Number(store_details.store_prep_time_in_mins) +
                      (store_details.store_delivery_time_in_mins
                        ? Number(store_details.store_delivery_time_in_mins)
                        : 0)}{' '}
                    mins
                  </p>
                </div>
                <div className='timeMode'>
                  <img src={deliveryMan} alt='shipping' />
                  <p>
                    {currencySymbol(store_details.store_currency)}{' '}
                    {store_details.store_delivery_fee}
                  </p>
                </div>
                <div className='timeMode'>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderDiscountTooltip}>
                    <div>
                      <img src={discount1} alt='discount' />
                      <p>
                        {discount(store_details)}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </p>
                    </div>
                  </OverlayTrigger>

                  {/* <img src={discount1} alt='discount' />
                  <p>
                    {discount(store_details)}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </p> */}
                </div>
              </div>
            </Tab>
            <Tab eventKey='pickup' title={pickup}>
              <div className='deliveryTime'>
                <div className='timeMode'>
                  <img src={stopwatch} alt='time' />
                  <p>{store_details.store_prep_time_in_mins} mins</p>
                </div>
                <div className='timeMode'>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderDiscountTooltip}>
                    <div>
                      <img src={discount1} alt='discount' />
                      <p>
                        {discount(store_details)}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </p>
                    </div>
                  </OverlayTrigger>
                  {/* <img src={discount1} alt='discount' />
                  <p>
                    {discount(store_details)}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </p> */}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className='menuHours'>
          <p>{menuTime}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
