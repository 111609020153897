/* eslint-disable jsx-a11y/anchor-is-valid */
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalPage from "../modal/ModalPage";
import "./RazorPay.css";

const RazorPay = () => {
    const [openAddress, setOpenAddress] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="ProcessToPayWrapper razorPay">
            <h6>Razorpay page for payment</h6>
            <div className="bottomfixbtn">
                <button onClick={() => setShowModal(true)}>Proceed to pay</button>
            </div>
            <ModalPage setShowModal={setShowModal} showModal={showModal} />
            {openAddress ? (
                <div className="AddAddress">
                    <div className="closeicon">
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => setOpenAddress(false)}
                        />
                    </div>
                    <div className="AddAddressform">
                        <div className="input">
                            <input type="text" placeholder="Name*" />
                        </div>
                        <div className="input">
                            <input type="number" placeholder="Phone Number*" />
                        </div>
                        <div className="input">
                            <input type="text" placeholder="Address Line 1*" />
                        </div>
                        <div className="input">
                            <input type="text" placeholder="Address Line 2*" />
                        </div>
                        <div className="input">
                            <input type="text" placeholder="Landmark (Optional)" />
                        </div>
                        <div className="inputtwo">
                            <div className="input">
                                <input type="text" placeholder="City (AUTOFILL)" />
                            </div>
                            <div className="input">
                                <input type="text" placeholder="State (AUTOFILL)" />
                            </div>
                        </div>
                        <div className="inputtwo">
                            <div className="input">
                                <input type="text" placeholder="Country (AUTOFILL)" />
                            </div>
                            <div className="input">
                                <input type="number" placeholder="Pincode (AUTOFILL)" />
                            </div>
                        </div>
                        <div className="selectTag">
                            <p>
                                TAG<sup>*</sup>
                            </p>
                            <button>Home</button>
                            <button>Office</button>
                            <button>Others</button>
                        </div>
                    </div>
                    <div className="AddAddressBtn">
                        <button>Add Address</button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default RazorPay;
